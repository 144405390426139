import { PACsComparator } from '@interfaces/pacsComparator.interface';
import { create } from 'zustand';

export interface PriceComparatorStoreState {
  contentPacs: PACsComparator[];
  uploadDate: string;
  uploadTime: string;
  setData: (data: any) => void;
}

const priceComparatorStore = create<PriceComparatorStoreState>()((set) => ({
  contentPacs: [],
  uploadDate: '',
  uploadTime: '',
  setData: (data: Omit<PriceComparatorStoreState, 'setData'>) => {
    set({
      contentPacs: data.contentPacs,
      uploadDate: data.uploadDate,
      uploadTime: data.uploadTime,
    });
  },
}));

export const usePriceComparatorStore = () =>
  priceComparatorStore((state) => {
    return {
      contentPacs: state.contentPacs,
      uploadDate: state.uploadDate,
      uploadTime: state.uploadTime,
    };
  });

export const useSetPriceComparatorData = () =>
  priceComparatorStore((state) => state.setData);
